<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon"></Breadcrumb>
    <div style="display: flex">
      <el-select v-model="state" placeholder="请选择查询类别">
        <el-option label="用户趣宝盒ID" value="0"></el-option>
        <!-- <el-option label="上级手机号" value="lastPhone"></el-option>
        <el-option label="上级社会实践码" value="lastCode"></el-option> -->
        <el-option label="用户手机号" value="1"></el-option>
      </el-select>
      <el-input
        placeholder="请先选择类别"
        :disabled="true"
        v-if="this.state == ''"
      >
      </el-input>
      <div v-else>
        <el-select
          v-model="valueArea"
          filterable
          clearable
          placeholder="请选择区号(可搜索关键字)"
          style="width: 240px; margin-left: 10px"
          v-if="this.state == '1' && internationType"
        >
          <el-option
            v-for="item in options"
            :key="item.name"
            :label="item.name + item.tel"
            :value="item.tel"
          >
          </el-option>
        </el-select>

        <el-input
          v-model="message"
          width="200"
          placeholder="请输入要查询的内容"
        ></el-input>
      </div>

      <el-button type="primary" @click="searchList">查询</el-button>
    </div>

    <div class="table_con">
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        height="100%"
        row-key="userId"
        :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      >
        <el-table-column type="index" label="序号" width="80" align="center">
        </el-table-column>
        <el-table-column prop="phone" label="用户手机号" width="150">
          <template slot-scope="scope">
            {{ scope.row.phoneArea }} {{ scope.row.phone }}
          </template>
        </el-table-column>
        <el-table-column prop="boxId" label="ID" width="170"> </el-table-column>
        <el-table-column prop="wallet" label="用户钱包地址" width="390">
        </el-table-column>
        <el-table-column prop="cple" label="质押 CPLE 数量" width="130">
        </el-table-column>
        <el-table-column prop="teamCount" label="团队人数" width="130">
        </el-table-column>
        <el-table-column prop="profitAdd" label="累计收益" width="140">
        </el-table-column>
        <el-table-column prop="activationDate" label="激活日期" width="180">
        </el-table-column>
      </el-table>

      <Pagination
        v-show="pageLength >= 0"
        :total="pageLength"
        :limit.sync="pageSize"
        @pagination="handlePageChange"
        :page-sizes="pageSizes"
      />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";

import { showLoading, hideLoading } from "@/util/loading";
import { areaCodeData } from "@/util/areaCodeData";

export default {
  components: {
    Breadcrumb,
    Pagination,
  },
  data() {
    return {
      valueArea: "",
      options: [],
      state: "",
      message: "",
      tableData: [],

      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "趣宝盒数据",
        },
        {
          name: "上下级关系",
        },
      ], // 面包屑数据

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 20, 50, 100, 200],

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;

    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");
  },

  methods: {
    searchList() {
      if (this.message) {
        showLoading();
        const opt = {
          url: reqUrl.getUserSuperior,
          method: "POST",
          params: JSON.stringify({
            msg: this.message,
            nonce: "",
            pageNum: 0,
            pageSize: 0,
            sign: "",
            token: "",
            type: this.state,
            phoneArea: this.valueArea || this.phoneAreaROLE,
          }),
          resFunc: (res) => {
            if (res.data.code != 0) {
              this.$notify.error({
                title: "错误",
                message: res.data.message,
              });
              hideLoading();
            }
            if (res.data.data) {
              this.tableData = res.data.data.list;
              this.pageLength = res.data.data.total;
              this.pageSize = res.data.data.pageSize;
            }

            hideLoading();
          },
          errFunc: (err) => {
            this.$notify.error({
              title: "错误",
              message: err,
            });
            hideLoading();
            console.log(err, "err1");
          },
        };
        request(opt);
      } else {
        this.$notify.warning({
          title: "警告",
          message: "暂无可搜索项",
        });
      }
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getUserSuperior,
        method: "POST",
        params: JSON.stringify({
          msg: this.message,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          nonce: "",
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          sign: "",
          token: "",
          type: this.state,
        }),
        resFunc: (res) => {
          console.log(res.data.data);

          if (res.data.data) {
            this.tableData = res.data.data.list;
            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
          }

          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          console.log(err, "err1");
          hideLoading();
        },
      };
      request(opt);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-button {
  margin-left: 20px;
}

::v-deep .el-table [class*="el-table__row--level"] .el-table__expand-icon {
  top: calc(50% - 10px);
  right: 21px;
  position: absolute;
}

.el-input {
  width: 300px;
  margin-left: 10px;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #e6e7e7;
  border-color: #d3d4d6;
  color: #7c7d7e;
}
</style>
